<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/lessonschedule/pt' }">私教列表</el-breadcrumb-item>
                <el-breadcrumb-item>私教详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">
            <!--标题-->
            <el-row>
                <el-col :span="12">
                    <h3>{{ page_name }}</h3>
                </el-col>
            </el-row>
            <div style="height: 20px;"></div>
            <!--详情-->
            <div>
                <div style="height: 20px;"></div>
                <!--详情-->
                <el-form class="time-arrange-form" v-loading="loading" size="medium" :label-width="this.env.label_width">
                    <el-row>
                        <el-col :span="16">
                            <el-form-item label="选择教练">
                                <span class="form-span-text">{{ form.user_nick }}</span>
                            </el-form-item>
                            <el-form-item label="选择课程">
                                <span class="form-span-text">{{ form.lesson_name }}</span>
                            </el-form-item>
                            <el-form-item label="选择门店" v-if="!this.Tool.is_empty(form.coach_uuid)">
                                <el-tag style="margin-right: 10px" :key="index" v-for="(item, index) in form.store_s"
                                    type="info" effect="light">
                                    {{ item.store_name }}
                                </el-tag>
                            </el-form-item>
                            <el-form-item label="课程标题">
                                <span class="form-span-text">{{ form.title }}</span>
                            </el-form-item>
                            <el-form-item label="课程时长">
                                <span class="span-input">{{ form.lesson_duration }}分钟</span>
                            </el-form-item>
                            <el-form-item v-if="!this.Tool.is_empty(form.sku)" label="价格">
                                <div v-for="(item, index) in form.sku" :key="index">
                                    <div class="span-input" style="margin-bottom: 8px">
                                        <el-tag style="min-width: 50px" size="medium">1v{{ item.qty }}</el-tag>
                                        <el-tag style="min-width: 50px" size="medium" type="success">{{ item.lesson_count
                                        }}节
                                        </el-tag>
                                        <span>{{
                                            `￥${item.price} - 有效期：${item.validity_time === 0 ? "不限制" : item.validity_time +
                                                "天"
                                                }`
                                        }}</span>
                                    </div>
                                </div>
                            </el-form-item>
                            <el-form-item label="提示(选填)">
                                <span class="form-span-text">{{ form.tip }}</span>
                            </el-form-item>
                            <el-form-item v-if="form.bookings" label="预约人数">
                                <span>累计{{ form.bookings_sum }}人</span>
                            </el-form-item>
                            <el-form-item>
                                <el-button size="medium" @click="isreturn">返回</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>


            </div>
        </div>

    </div>
</template>
<script>
export default {
    data() {
        return {
            page_name: '私教详情',
            loading: false,                 // 加载状态
            select_loading: false,          // 加载状态
            form: {}
        }
    },
    // 创建
    created() {
        this.init()
    },
    methods: {
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 初始化
        init() {
            let good_uuid = this.$route.query.good_uuid
            if (!this.Tool.is_empty(good_uuid)) {
                this.good_uuid = good_uuid
                this.getInfo(good_uuid)
            }
        },
        // 获取排课信息
        getInfo(good_uuid = '') {
            let postdata = {
                api_name: "lessonschedule.pt.getinfo",
                token: this.Tool.get_l_cache('token'),
                good_uuid,
            }
            this.loading = true
            this.Tool.post_data('merchant', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.form = json.data
                } else {
                    this.Tool.errormes(json)
                }

            })
        },
        // 返回
        isreturn() {
            // this.$router.push({path: '/lessonschedule/pt'})
            this.$router.go(-1)
        },
    }
}
</script>
